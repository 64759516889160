import React from 'react'
import cookies from "js-cookie";
import { useTranslation } from 'react-i18next';
// import phone_botton_icon from '../images/phone_botton_icon.svg'
// import whatsapp_botton_icon from '../images/whatsapp_botton_icon.svg'
import whatsapp_botton_icon from '../images/whatsapp_icon.png'

import hi_five from '../images/hi_five.svg'

import { AllCakesItems } from './Data';
import { AllOffersItems } from './Data';

const ExploreProjects = () => {
    const currentLanguageCode = cookies.get('i18next');
    const { t } = useTranslation();

    return (
        <>
            {/* Mobile */}
            <div className='md:hidden mb-20 mt-20'>
                <div className='flex justify-center gap-2 text-3xl font-bold'>
                    <h1>{t('Explor_Our_Services')}</h1>
                    <img src={hi_five} alt='hi_five' />
                </div>

                {AllOffersItems.map((item) => (
                    <div key={item.id}>
                        {currentLanguageCode === 'en' ?
                            <h1 className='ms-3 text-[24px] font-bold mt-12'>{item.CategoryNameEN}</h1>
                            :
                            <h1 className='ms-3 text-[24px] font-bold mt-12'>{item.CategoryNameAR}</h1>
                        }
                        <div className="overflow-x-scroll ps-3 py-2 flex">
                            {item.Cakes.map((card) => (
                                <div key={card.id} className='group flex-none w-[250px] shadow-md me-4 rounded-[20px]'>
                                    <div className='flex flex-col items-center'>
                                        <img className='w-[250px] h-[187px] rounded-t-[20px]' src={card.img} alt='product_Image_01' />
                                        {currentLanguageCode === 'en' ? (
                                            <>
                                                <h1 className='font-bold text-[12px] text-center mt-4'>{card.itemNameEn + " " + card.id}</h1>
                                                <h1 className='text-[#494949] text-[12px] text-center my-[6px]'>{card.itemDescreptionEn}</h1>
                                            </>
                                        ) : (
                                            <>
                                                <h1 className='font-bold text-[12px] text-center mt-4'>{card.itemNameAR + " " + card.id}</h1>
                                                <h1 className='text-[#494949] text-[12px] text-center my-[6px]'>{card.itemDescreptionAR}</h1>
                                            </>
                                        )}

                                        {/* <h1 className='text-[12px] mb-4'>
                                            {t('Starts_from')}
                                            <span className='text-[#D8195B] font-bold'> {card.price} {t("currency")}</span>
                                        </h1> */}

                                        {/* Old Design | Two Buttons */}
                                        {/* <div className='flex justify-between w-full px-3 mb-3 gap-6 mx-3'>
                                            <button
                                                onClick={() => window.open(`tel:+966571220234`)}
                                                className='w-full group-active:animate-bounce'>
                                                <div className='bg-black py-[5px] px-[12px] rounded-[11px] text-white flex justify-between'>
                                                    <h1 className='text-[12px]'>{t('Phone')}</h1>
                                                    <img className='w-5 h-5' src={phone_botton_icon} alt='phone_botton_icon' />
                                                </div>
                                            </button>
                                            <button
                                                onClick={() => window.open(`https://wa.me/+966571220234?text=${currentLanguageCode === 'en' ? "I want to ask about " : "أرغب في الاستفسار عن "}${currentLanguageCode === 'en' ? card.itemNameEn + " " + card.id : card.itemNameAR + " " + card.id}`)}
                                                className='w-full group-active:animate-upDown'>
                                                <div className='bg-[#D8195B] py-[5px] px-[12px] rounded-[11px] text-white flex justify-between'>
                                                    <h1 className='text-[12px]'>{t('Whats')}</h1>
                                                    <img className='w-5 h-5' src={whatsapp_botton_icon} alt='whatsapp_botton_icon' />
                                                </div>
                                            </button>
                                        </div> */}

                                        {/* New Design | One Button */}
                                        <div className='flex justify-between w-full px-3 mb-3 mt-3 gap-6 mx-3'>
                                            <button
                                                onClick={() => window.open(`https://wa.me/+966570075668?text=${currentLanguageCode === 'en' ? "I would like to obtain discount codes for Shahad Cakery customers" : "أرغب في الحصول على أكواد الخصم الخاصة بعملاء شهد كيكري"}`)}
                                                className='w-full group-active:animate-upDown'>
                                                <div className='relative bg-[#D8195B] py-[5px] px-[12px] rounded-[11px] text-white flex justify-center '>
                                                    <h1 className='text-[16px] font-bold'>{t('ask_for_coupon')}</h1>
                                                    <img className='absolute top-1/2 transform -translate-y-1/2 end-4 w-5 h-5' src={whatsapp_botton_icon} alt='whatsapp_botton_icon' />
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}

                {AllCakesItems.map((item) => (
                    <div key={item.id}>
                        {currentLanguageCode === 'en' ?
                            <h1 className='ms-3 text-[24px] font-bold mt-12'>{item.CategoryNameEN}</h1>
                            :
                            <h1 className='ms-3 text-[24px] font-bold mt-12'>{item.CategoryNameAR}</h1>
                        }
                        <div className="overflow-x-scroll ps-3 py-2 flex">
                            {item.Cakes.map((card) => (
                                <div key={card.id} className='group flex-none w-[250px] shadow-md me-4 rounded-[20px]'>
                                    <div className='flex flex-col items-center'>
                                        <img className='w-[250px] h-[187px] rounded-t-[20px]' src={card.img} alt='product_Image_01' />
                                        {currentLanguageCode === 'en' ? (
                                            <>
                                                <h1 className='font-bold text-[12px] mt-4'>{card.itemNameEn + " " + card.id}</h1>
                                                <h1 className='text-[#494949] text-[12px] my-[6px]'>{card.itemDescreptionEn}</h1>
                                            </>
                                        ) : (
                                            <>
                                                <h1 className='font-bold text-[12px] mt-4'>{card.itemNameAR + " " + card.id}</h1>
                                                <h1 className='text-[#494949] text-[12px] my-[6px]'>{card.itemDescreptionAR}</h1>
                                            </>
                                        )}

                                        <h1 className='text-[12px] mb-4'>
                                            {t('Starts_from')}
                                            <span className='text-[#D8195B] font-bold'> {card.price} {t("currency")}</span>
                                        </h1>

                                        {/* Old Design | Two Buttons */}
                                        {/* <div className='flex justify-between w-full px-3 mb-3 gap-6 mx-3'>
                                            <button
                                                onClick={() => window.open(`tel:+966571220234`)}
                                                className='w-full group-active:animate-bounce'>
                                                <div className='bg-black py-[5px] px-[12px] rounded-[11px] text-white flex justify-between'>
                                                    <h1 className='text-[12px]'>{t('Phone')}</h1>
                                                    <img className='w-5 h-5' src={phone_botton_icon} alt='phone_botton_icon' />
                                                </div>
                                            </button>
                                            <button
                                                onClick={() => window.open(`https://wa.me/+966571220234?text=${currentLanguageCode === 'en' ? "I want to ask about " : "أرغب في الاستفسار عن "}${currentLanguageCode === 'en' ? card.itemNameEn + " " + card.id : card.itemNameAR + " " + card.id}`)}
                                                className='w-full group-active:animate-upDown'>
                                                <div className='bg-[#D8195B] py-[5px] px-[12px] rounded-[11px] text-white flex justify-between'>
                                                    <h1 className='text-[12px]'>{t('Whats')}</h1>
                                                    <img className='w-5 h-5' src={whatsapp_botton_icon} alt='whatsapp_botton_icon' />
                                                </div>
                                            </button>
                                        </div> */}

                                        {/* New Design | One Button */}
                                        <div className='flex justify-between w-full px-3 mb-3 gap-6 mx-3'>
                                            <button
                                                onClick={() => window.open(`https://wa.me/+966571220234?text=${currentLanguageCode === 'en' ? "I want to ask about " : "أرغب في الاستفسار عن "}${currentLanguageCode === 'en' ? card.itemNameEn + " " + card.id : card.itemNameAR + " " + card.id}`)}
                                                className='w-full group-active:animate-upDown'>
                                                <div className='relative bg-[#D8195B] py-[5px] px-[12px] rounded-[11px] text-white flex justify-center '>
                                                    <h1 className='text-[16px] font-bold'>{t('Whats')}</h1>
                                                    <img className='absolute top-1/2 transform -translate-y-1/2 end-4 w-5 h-5' src={whatsapp_botton_icon} alt='whatsapp_botton_icon' />
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}

            </div>
            {/* Desktop */}
            <div className='hidden md:block'> Desktop</div>
        </>
    )
}

export default ExploreProjects